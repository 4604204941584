<template>
    <div class="box is-flex is-flex-direction-column is-height-fit pr-6">
        <PageHead title="Account" />

        <div class="box-title">
            Account
        </div>
        <div class="is-size-2 has-text-weight-semibold">{{ user.firstName }} {{ user.middleName }} {{ user.lastName }}
        </div>
        <div class="is-size-4">{{ user.email }}</div>
        <div class="is-flex has-gap-small mt-5">
            <Link :href="route('profile.orders')" class="button is-primary has-gap-small">
                <Icon :icon="mdiPackageVariant" />
                Your Orders
            </Link>
            <button @click="handleSignOut" class="button is-warning has-gap-small">
                <Icon :icon="mdiKey" />
                Sign Out
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import Icon from "@/Components/Icon.vue";
import PageHead from "@/Components/PageHead.vue";
import Base from "@/Layouts/Base.vue";
import Default from "@/Layouts/Default.vue";
import { User } from "@/Types/User";
import { Link, router } from "@inertiajs/vue3";
import { mdiKey, mdiPackageVariant } from "@mdi/js";

defineOptions({
    layout: [Base, Default],
})

const props = defineProps<{
    user: User;
}>();

async function handleSignOut() {
    router.post(route('logout'));
}
</script>
