<template>
    <div>
        <nav class="pagination">
            <ul class="pagination-list">
                <li>
                    <a
                        class="pagination-link"
                        :class="{'is-disabled': currentPage === 1}"
                        @click.prevent="emit('update:page', currentPage - 1)"
                    >
                        <span class="icon-text">
                            <span class="icon is-small">
                                <Icon :icon="mdiArrowLeft" />
                            </span>
                            <span>
                                Back
                            </span>
                        </span>
                    </a>
                </li>

                <li
                    v-for="(page, index) in pageList"
                    :key="index"
                >
                    <a
                        v-if="page !== '...'"
                        class="pagination-link"
                        :class="{'is-current': page === currentPage}"
                        @click.prevent="emit('update:page', page)"
                    >
                        {{ page }}
                    </a>

                    <span
                        v-else
                        class="pagination-ellipsis"
                    >
                        &hellip;
                    </span>
                </li>

                <li>
                    <a
                        class="pagination-link"
                        :class="{'is-disabled': currentPage === lastPage}"
                        @click.prevent="emit('update:page', currentPage + 1)"
                    >
                        <span class="icon-text">
                            <span class="icon is-small">
                                <Icon :icon="mdiArrowRight" />
                            </span>
                            <span>
                                Next
                            </span>
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">

import {computed} from "vue";
import {mdiArrowLeft, mdiArrowRight} from "@mdi/js";
import Icon from "@/Components/Icon.vue";

const props = withDefaults(defineProps<{
    currentPage: number,
    lastPage: number,
    perPage?: number,
    total: number,
    from: number,
    to: number,
    maxItems?: number,
}>(), {
    maxItems: 6
});

const emit = defineEmits<{
    (e: 'update:page', newPage: number): void;
}>();

const pageList = computed<(number | '...')[]>(() => {
    const pages: (number | '...')[] = [];
    const maxItems = props.maxItems!;
    const half = Math.floor((maxItems - 2) / 2); // Adjust half to account for first and last pages

    if (props.lastPage <= maxItems) {
        for (let i = 1; i <= props.lastPage; i++) {
            pages.push(i);
        }
    } else {
        let start = Math.max(2, props.currentPage - half);
        let end = Math.min(props.lastPage - 1, props.currentPage + half);

        if (start > 2) {
            pages.push(1);
            pages.push('...');
        } else {
            start = 1;
        }

        for (let i = start; i <= end; i++) {
            pages.push(i);
        }

        if (end < props.lastPage - 1) {
            pages.push('...');
            pages.push(props.lastPage);
        } else {
            end = props.lastPage;
            pages.push(end);
        }
    }

    return pages;
});

</script>
