<template>
    <div class="is-width-100">
        <PageHead title="Orders" />

        <div v-if="orders.length == 0" class="box is-size-4 has-text-centered is-width-100 is-height-fit">No orders
        </div>

        <div v-else class="order-grid is-width-100 is-height-fit">

            <Link class="box is-width-100 is-height-fit m-0 is-flex is-flex-direction-column has-gap-medium"
                v-for="order in orders" :href="route('orders.show', { order: order.id })">
            <div class="box-title bloc m-0">
                Order Placed {{ getDateCreated(order.dateCreated) }}
            </div>

            <div class="is-flex is-flex-direction-column has-gap-large is-width-100">
                <div class="is-flex is-align-items-center has-gap-medium is-size-4" v-for="item in order.lineItems">
                    <LoadingImg :src="item.imageUrl" class="item-thumb" width="100" height="100" />
                    <div class="is-flex is-flex-direction-column">
                        <div>{{ item.name }}</div>
                        <div class="is-size-6 mt-1">x{{ item.quantity }}</div>
                    </div>
                </div>
            </div>

            <div class="block is-flex is-align-items-baseline is-size-6 is-flex-wrap-wrap"
                style="row-gap: .5rem; column-gap: 3rem;">
                <div>Total: ${{ getTotalCost(order) }}</div>
                <div class="is-capitalized">Status: {{ order.status }}</div>
            </div>
            </Link>
        </div>

    </div>
</template>

<script setup lang="ts">

import LoadingImg from '@/Components/LoadingImg.vue';
import PageHead from '@/Components/PageHead.vue';
import Base from '@/Layouts/Base.vue';
import Default from '@/Layouts/Default.vue';
import { Order } from '@/Types/Order';
import { Link } from '@inertiajs/vue3';

defineOptions({
    layout: [Base, Default],
})

const props = defineProps<{
    orders: Order[];
    //TODO: add pagination
}>();

function getDateCreated(date: string) {
    return new Date(date).toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
}

function getTotalCost(order: Order) {
    return (order.subtotal + order.salesTax).toFixed(2);
}
</script>

<style lang="scss" scoped>
@use "bulma/sass/utilities/mixins";

.order-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
    gap: 2rem;
}

@include mixins.touch {
    .order-grid {
        display: flex;
        flex-direction: column;
    }
}

.item-thumb {
    width: 5rem;
    height: 5rem;
    border-radius: 0.5rem;
}
</style>
