<template>
    <div>

        <PageHead />

        <div ref="homeBanner"
            class="home-banner mb-6 is-flex is-flex-direction-column is-justify-content-center is-size-1-desktop is-size-2-tablet">

            <div class="home-banner-logo is-align-items-center is-justify-content-center">
                <img src="/public/imgs/level1.png">
                <div class="shop-text has-text-link has-text-weight-bold">Shop</div>
            </div>

            <div class="home-banner-text has-text-weight-semibold has-text-warning-65" style="font-size: .85em;">
                Grassroots Esports Merchandise
            </div>
            <div class="home-banner-text has-text-weight-bold has-text-white" style="font-size: 1.25em;">
                Directly From Your Favorite Organizers
            </div>
            <div class="home-banner-text has-text-weight-medium has-text-primary-75 mt-5" style="font-size: .55em;">
                Level1 works with event organizers to deliver quality merchandise to fans.
            </div>
            <div class="home-banner-text has-text-weight-medium has-text-primary-90 mt-2" style="font-size: .45em;">
                Free US and Canada shipping on all orders!
            </div>

        </div>

        <div class="shop-grid">
            <template v-if="isLoading" v-for="i in 6">
                <div class="box is-width-100 is-height-100 is-flex is-flex-direction-column has-gap-medium m-0">
                    <div class="banner">
                        <div class="skeleton-block is-height-100"></div>
                    </div>
                    <div class="is-flex has-gap-small is-size-3">
                        <div class="is-skeleton m-0 mr-3 is-flex-grow-1">loading</div>
                        <div class="is-skeleton m-0">loading</div>
                    </div>
                </div>
            </template>

            <template v-else>
                <template v-for="shop in shops">
                    <Link class="box is-width-100 is-height-100 is-flex is-flex-direction-column has-gap-medium m-0"
                        :href="route('shop.show', { shop: shop.slug })">

                    <LoadingImg class="banner" :src="shop.bannerUrl" />

                    <div
                        class="is-flex-tablet is-align-items-center is-justify-content-space-between has-gap-large my-auto">
                        <h2 class="is-size-3 is-size-4-mobile has-text-weight-medium">{{ shop.name }}</h2>

                        <div v-if="true" class="is-flex is-align-items-center has-gap-small">
                            <div v-if="shopState(shop.dateOpen, shop.dateClose) === 'open'" class="tag is-medium is-success">
                                Open
                            </div>
                            <div v-else-if="shopState(shop.dateOpen, shop.dateClose) === 'opening-soon'">
                                <div class="tag is-medium is-warning">Opening Soon</div>
                            </div>
                            <div v-else class="tag is-medium is-danger">Closed</div>

                            <div v-if="shopState(shop.dateOpen, shop.dateClose) === 'open' && showClosingInString(shop.dateClose)" class="tag is-medium is-warning pulse">{{ "Closes " + dayjs(shop.dateClose).fromNow() + "!" }}</div>
                        </div>

                        <div v-else>Closed</div>

                    </div>

                    </Link>
                </template>
            </template>
        </div>
    </div>

</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { Shop } from '@/Types/Shop';
import { Link } from '@inertiajs/vue3';
import { Item } from '@/Types/Item';
import { router } from '@inertiajs/vue3';
import LoadingImg from '@/Components/LoadingImg.vue';
import Base from '@/Layouts/Base.vue';
import HasCart from '@/Layouts/HasCart.vue';
import gsap from 'gsap';
import PageHead from '@/Components/PageHead.vue';
import dayjs from 'dayjs';
import { shopState } from '@/Helpers/shopTime';

const homeBanner = ref<HTMLElement | null>(null);

defineOptions({
    layout: [Base, HasCart],
});

const props = defineProps<{
    shops?: Shop[];
    itemsByShop?: Record<number, Item[]>;
}>();

const isLoading = ref(true);

onMounted(async () => {
    fetchShops();

    if (!homeBanner.value) {
        return;
    }

    const tl = gsap.timeline();

    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {

        tl.set(".home-banner-text", {
            display: 'none',
        })

        tl.fromTo('.home-banner-logo', {
            display: 'flex',
            opacity: 1,
            y: 0,
        }, {
            delay: 1.5,
            duration: .25,
            opacity: 0,
            display: 'none',
            y: -40,
            ease: 'power2.in',
        });

        tl.set(".home-banner-text", {
            display: 'block',
            opacity: 0,
        })

        tl.fromTo(".home-banner-text", {
            opacity: 0,
            y: 60,
        }, {
            y: 0,
            duration: .5,
            ease: 'power2.out',
            opacity: 1,
            stagger: .2
        }, '+=.1');
    } else {
        tl.fromTo('.home-banner-logo', {
            display: 'flex',
            opacity: 1,
            y: 0
        }, {
            delay: 1.5,
            duration: .25,
            opacity: 0,
            display: 'none',
            ease: 'power2.in',
        });

        tl.fromTo('.home-banner-text', {
            display: 'none',
            opacity: 0
        }, {
            opacity: 1,
            display: 'block',
            duration: .25,
            ease: 'power2.out',
        });
    }
});

function fetchShops() {
    router.reload({
        only: ['shops', 'itemsByShop'],

        onSuccess: () => {
            isLoading.value = false;
        },
        onError: (e) => {
            console.error(e);
        },
    });
}

function showClosingInString(end?: string) {
    if (!end) {
        return false;
    }
    const timeEnd = new Date(end);
    const now = new Date();

    const diff = timeEnd.getTime() - now.getTime();
    const days = Math.floor(diff / 1000 / 60 / 60 / 24);

    return days <= 3 && days >= 0;
}

</script>

<style lang="scss" scoped>
@use "bulma/sass/utilities/mixins";

.home-banner {
    width: 100%;
    height: 27rem;
    border-radius: 1rem;
    padding: 3rem 10%;
    box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.37);
    background: url('/public/home-header-bg.svg') var(--bulma-primary-15) repeat center;
    background-blend-mode: overlay;
    animation: header-background 20s infinite linear;
    overflow: hidden;

    .home-banner-logo {
        display: flex;
        font-size: .85rem;

        img {
            height: 8em;
            object-fit: contain;
        }

        .shop-text {
            font-size: 3.88em;
            margin-left: .5em;
            margin-top: .56em;
        }
    }
}

@media (prefers-reduced-motion: reduce) {
    .home-banner {
        animation: none;
    }
}

.shop-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));
    gap: 2rem;
    width: 100%;
}

.banner {
    width: 100%;
    height: fit-content;
    border-radius: .5rem;
    aspect-ratio: 4 / 1;
    margin-right: 0;
    object-fit: cover;
}

.pulse {
    animation: pulse 1.5s infinite;
    animation-timing-function: ease-in-out;
}

@include mixins.touch {
    .home-banner {
        padding: 2rem 5%;
        font-size: 1.75rem;

        .home-banner-logo {
            font-size: .65rem;
        }
    }

    .shop-grid {
        grid-template-columns: 1fr;
    }
}

@include mixins.mobile {
    .home-banner .home-banner-logo {
        font-size: .38rem;
    }
}

@keyframes pulse {
    0% {
        filter: brightness(1);
    }

    50% {
        filter: brightness(.75);
    }

    100% {
        filter: brightness(1);
    }
}

@keyframes header-background {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 240px 120px;
    }
}
</style>
