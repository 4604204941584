<template>
    <div>
        <HeaderBanner v-if="page?.props?.auth?.user !== undefined && page?.props?.auth?.user?.dateEmailVerified === null">
            <div class="is-flex is-align-items-center is-width-100 has-gap-medium is-flex-wrap-wrap">
                <p>
                    Your email is not verified. Please check your email for a verification link.
                </p>
                <Link :href="resent ? '#' : route('profile.resend_verification_email')" @click="resent = true;">
                    <button class="button is-primary" :disabled="resent">
                        <template v-if="resent">
                            Check Your Inbox
                        </template>
                        <template v-else>
                            Resend Verification Email
                        </template>
                    </button>
                </Link>
            </div>
        </HeaderBanner>
        <AppHeader />
        <transition name="layout" mode="out-in" appear>
            <slot />
        </transition>
        <AppFooter />
        <Transition name="fade">
            <div v-if="usingTheme" class="background shop" ref="backgroundShop"></div>
        </Transition>
        <div class="background base"></div>
        <PageLoading :loading="loading" />
    </div>
</template>

<script setup lang="ts">
import AppHeader from '@/Components/AppHeader.vue';
import AppFooter from '@/Components/AppFooter.vue';
import { router, usePage } from '@inertiajs/vue3';
import { nextTick, onMounted, ref, watch } from 'vue';
import { useShopStore } from '@/Stores/ShopStore';
import gsap from 'gsap';
import 'delayed-scroll-restoration-polyfill';
import PageLoading from '@/Components/PageLoading.vue';
import HeaderBanner from '@/Components/HeaderBanner.vue';
import { Link } from '@inertiajs/vue3';

const resent = ref(false);
const page = usePage();
const shop = useShopStore();
const backgroundShop = ref<HTMLDivElement | null>(null);
const usingTheme = ref(false);
const themeId = ref(-1);
const themeColor = ref('');
const themeImage = ref('');
const loading = ref(false);

onMounted(() => {
    shop.initialize(Number(page.props.shopId));
    document.documentElement.classList.add('theme-dark');
    themeId.value = page.props.shopId;
});

router.on('start', () => {
    loading.value = true;
});

router.on('finish', () => {
    loading.value = false;
});

watch(() => page.props.shopId, () => {
    if (page.props.shopId === undefined) {
        usingTheme.value = false;
    } else if (themeId.value === page.props.shopId) {
        usingTheme.value = true;
    } else {
        themeId.value = page.props.shopId;
    }
});

watch(() => shop.loading, (shopLoad) => {
    if (!usingTheme.value && !shopLoad) {
        usingTheme.value = true;
    }
});

watch(() => usingTheme.value, (using) => {
    if (!using) {
        themeColor.value = '';
        themeImage.value = '';
    } else if (themeId.value === shop.id) {
        themeColor.value = shop.theme.color;
        themeImage.value = shop.theme.image;
    }
});

watch(() => shop.theme.color, (newColor) => {
    if (usingTheme.value) {
        themeColor.value = newColor;
    }
});

watch(() => shop.theme.image, (newImage) => {
    if (usingTheme.value) {
        themeImage.value = newImage;
    }
});

watch(() => themeColor.value, (color) => {
    const documentElement = document.documentElement;

    if (color && color !== '') {
        const hsl = hexToHSL(color);

        documentElement.setAttribute('data-theme', 'themed-shop');
        documentElement.style.setProperty('--themed-primary-h', hsl.h + 'deg');
        documentElement.style.setProperty('--themed-primary-s', Math.min(hsl.s, 60) + '%');
        documentElement.style.setProperty('--themed-warning-h', hsl.h + 'deg');
        documentElement.style.setProperty('--themed-warning-s', Math.min(hsl.s, 60) + '%');

        const light = Math.min(Math.max(5, hsl.l), 20);

        nextTick(() => {
            gsap.set(backgroundShop.value, {
                backgroundColor: `hsl(${hsl.h}deg, ${hsl.s}%, ${light}%)`,
            })
        });
    } else {
        documentElement.setAttribute('data-theme', '');
        documentElement.style.removeProperty('--themed-primary-h');
        documentElement.style.removeProperty('--themed-primary-s');
        documentElement.style.removeProperty('--themed-warning-h');
        documentElement.style.removeProperty('--themed-warning-s');

        nextTick(() => {
            gsap.set(backgroundShop.value, {
                backgroundColor: '',
            });
        });
    }
});

watch(() => themeImage.value, (image) => {
    if (image && image !== '') {
        nextTick(() => {
            gsap.set(backgroundShop.value, {
                backgroundImage: `url('${image}')`,
                filter: 'brightness(0.5)',
            });
        })
    } else {
        nextTick(() => {
            gsap.set(backgroundShop.value, {
                backgroundImage: '',
                filter: '',
            });
        });
    }
});

//https://css-tricks.com/converting-color-spaces-in-javascript/
function hexToHSL(H) {
    if (H.length === 0) {
        return undefined;
    }

    // Convert hex to RGB first
    let r: any = 0, g: any = 0, b: any = 0;
    if (H.length == 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
    } else if (H.length == 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta == 0)
        h = 0;
    else if (cmax == r)
        h = ((g - b) / delta) % 6;
    else if (cmax == g)
        h = (b - r) / delta + 2;
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
        h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return {
        h: h,
        s: s,
        l: l
    };
}
</script>

<style lang="scss">
@use 'bulma/sass/utilities/mixins';

[data-theme=themed-shop]:root {
    --themed-primary-h: 0deg;
    --themed-primary-s: 0%;
    --themed-primary-l: 35%;
    --themed-warning-h: 0deg;
    --themed-warning-s: 0%;
    --themed-warning-l: 82%;
    --bulma-primary-h: var(--themed-primary-h);
    --bulma-primary-s: var(--themed-primary-s);
    --bulma-primary-l: var(--themed-primary-l);
    --bulma-warning-h: var(--themed-warning-h);
    --bulma-warning-s: var(--themed-warning-s);
    --bulma-warning-l: var(--themed-warning-l);
    --bulma-skeleton-background: var(--bulma-primary);
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.background.base {
    z-index: -100;
    background-color: rgba($color: rgb(0, 0, 0), $alpha: 0.35);
    background-image: url('/public/imgs/background.webp');
    background-blend-mode: darken;
}

.background.shop {
    z-index: -99;
}

.page-outer {
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
    max-width: 1600px;
    padding: 3rem 1rem;
    min-height: calc(100dvh - 7rem - 6rem);
}

@include mixins.mobile {
    .page-outer {
        max-width: 100%;
        width: 100%;
        padding: 1rem .25rem;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s linear;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
