import { createApp, h, DefineComponent } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { ZiggyVue } from 'ziggy-js';
import { createPinia } from 'pinia';
import DefaultLayout from '@/Layouts/Default.vue';
import gsap from 'gsap';
import { Flip } from 'gsap/all';
import {DayJsPlugin} from "@/Plugins/DayJsPlugin";
import * as Sentry from "@sentry/vue";

const pinia = createPinia();
gsap.registerPlugin(Flip);

createInertiaApp({
    progress: false,
    resolve: name => {
        const pages = import.meta.glob<true, string, DefineComponent>('./Pages/**/*.vue', { eager: true });
        let page = pages[`./Pages/${name}.vue`];
        page.default.layout = page.default.layout || DefaultLayout;
        return page;
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        try {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN,
                integrations: [
                    Sentry.replayIntegration(),
                ],
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
        } catch (_e) {
            // Do nothing.
        }

            app.use(plugin)
            .use(pinia)
            .use(ZiggyVue)
            .use(DayJsPlugin)
            .mount(el);
    },
});
