<template>
    <div>
        <template v-if="isLoading">
            <div class="is-flex is-align-items-center">
                <div class="mr-3 is-skeleton" style="width: 80px; height: 80px;"></div>
                <div v-if="minimize" class="skeleton-lines is-flex-grow-1">
                    <div />
                    <div />
                    <div />
                </div>
                <div v-else class="skeleton-block is-flex-grow-1"></div>
            </div>
        </template>

        <template v-else-if="cartItem">
            <div
                class="is-flex has-gap-medium"
                :class="{ 'is-flex-wrap-wrap is-align-items-center': !minimize }"
            >

                <template v-if="cartItem.item.images && cartItem.item.images.length > 0">
                    <LoadingImg
                        v-if="minimize"
                        :src="cartItem.item.images[0].url"
                        class="cart-image"
                        placeholder
                        placeholder-class="cart-image-placeholder"
                        :alt="cartItem.item.title"
                    />
                    <LoadingImg
                        v-else
                        :src="cartItem.item.images[0].url"
                        class="cart-image large"
                        placeholder
                        placeholder-class="cart-image-placeholder"
                        :alt="cartItem.item.title"
                    />
                </template>

                <template v-else>
                    <div
                        v-if="minimize"
                        class="cart-image cart-image-placeholder"
                        style="min-width: 70px; height: 70px;"
                    >
                    </div>
                    <div
                        v-else
                        class="cart-image cart-image-placeholder"
                        style="width: 100px; height: 100px;"
                    />
                </template>

                <div
                    class="is-flex is-flex-direction-column mr-auto my-auto"
                    style="gap: .5rem;"
                    :class="{
                        'cart-item-title': !minimize
                    }"
                >

                    <div
                        :class="{
                            'mr-6 is-size-4': !minimize,
                            'is-size-5': minimize
                        }"
                        style="line-height: 1.4em;"
                    >
                        {{ cartItem.item.title }}
                    </div>

                    <div
                        v-if="cartItem.variant"
                        class="is-flex is-flex-wrap-wrap is-align-items-center has-gap-small hide-no-children"
                    >
                        <div
                            class="tag is-primary px-2 is-size-6"
                            :class='{ "px-3": !minimize }'
                        >
                            {{ cartItem.variant.variantName }}
                        </div>
                    </div>

                    <div v-if="!props.editable && !props.minimize" class="has-text-grey-lighter">
                        Quantity: {{ cartItem.quantity }}
                    </div>

                    <template v-if="props.minimize">
                        <div class="is-flex is-align-items-center is-flex-wrap-wrap has-gap-small is-size-6">
                            <div>x{{ cartItem.quantity }}</div>
                            <div>${{ (cartItem.item.price * (cartItem.quantity ?? 1)).toFixed(2) }}</div>
                        </div>
                    </template>
                </div>

                <div class="is-flex is-align-items-center has-gap-medium">

                    <template v-if="props.editable && !props.minimize">
                        <div class="is-flex has-gap-medium is-align-items-center">
                            <label for="quantity" class="is-hidden-mobile">Quantity</label>
                            <QuantitySelector :value="cartItem.quantity" :min="1" :max="99" :clientSide="false" @change="updateQuantity" />
                        </div>

                        <button class="button is-danger is-outlined" @click="removeFromCart">
                            <Icon :icon="mdiTrashCanOutline" />
                        </button>
                    </template>

                    <template v-if="!props.minimize && !props.editable">
                        <div class="is-size-5">x{{ cartItem.quantity }}</div>
                    </template>

                    <template v-if="!props.minimize">
                        <div class="is-size-5 has-text-weight-semibold maximize-price">${{ (cartItem.item.price * (cartItem.quantity ??
                            1)).toFixed(2)
                            }}
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { PropType, onMounted } from 'vue';
import { mdiTrashCanOutline } from '@mdi/js';
import { useCartStore } from '@/Stores/CartStore';
import LoadingImg from './LoadingImg.vue';
import { CartItem } from '@/Types/CartItem';
import Icon from "@/Components/Icon.vue";
import QuantitySelector from './QuantitySelector.vue';

const props = defineProps({
    cartItem: {
        type: Object as PropType<CartItem>,
        required: false
    },
    index: {
        type: Number,
        required: false
    },
    minimize: {
        type: Boolean,
        default: false
    },
    editable: {
        type: Boolean,
        default: true
    },
    isLoading: {
        type: Boolean,
        default: false
    }
});

const cart = useCartStore();

onMounted(() => {
    if (props.cartItem && !props.cartItem?.quantity) {
        props.cartItem.quantity = 1;
    }
    cart.initialize();
});

async function removeFromCart() {
    if (!props.cartItem) {
        return;
    }
    await cart.deleteByIndex(props.index);
}

async function updateQuantity(amount: number) {
    if (!props.cartItem) {
        return;
    }
    await cart.updateQuantity(props.index, amount);
}

</script>

<style lang="scss">
.divider {
    padding: 1rem 0;
    border-bottom: 1px solid #ffffff20;
}

.cart-image {
    border-radius: .5rem;
    background-color: #ffffff20;
    width: 70px;
    height: 70px;
}

.cart-image.large {
    width: 100px;
    height: 100px;
}

.cart-image-placeholder {
    background-color: #ffffff20;
}

.quantity-adjust {
    width: 2rem;
    padding-left: 0;
    padding-right: 0;
}

.maximize-price {
    min-width: 5rem;
    text-align: right;
}

.cart-item-title {
    width: 35rem;
}

.hide-no-children:empty {
    display: none !important;
}
</style>
