<template>
    <ItemVariantDialog
        :open="variantModalModel !== null"
        @close="handleModalClose"
        @save="handleVariantSave"
        :model-value="variantModalModel"
        :show-stock-limit="itemForm.stockLimitType === 'BY_VARIANT'"
        :provider="itemForm.fulfillmentOptions?.provider"
        :product-id="itemForm.fulfillmentOptions?.providerIdentifier?.toString?.()"
    />

    <div class="columns is-multiline">
        <div class="column is-12">
            <Field
                v-model="itemForm.title"
                label="Title"
                :error="itemForm.errors.title"
            />
        </div>
        <div class="column is-12">
            <Field
                is="textarea"
                rows="5"
                v-model="itemForm.description"
                label="Description"
                :error="itemForm.errors.description"
            />
        </div>
        <div class="column is-12">
            <Field
                v-model="itemForm.price as any"
                type="number"
                label="Price"
                :error="itemForm.errors.price"
            />
        </div>
        <div class="column is-12">
            <Field
                v-model="itemForm.goalContribution as any"
                type="number"
                label="Goal Contribution"
                :error="itemForm.errors.goalContribution"
            />
        </div>
        <div class="column is-12">
            <Field
                is="select"
                v-model="itemForm.category"
                :error="itemForm.errors.category"
                label="Category"
                helperText="Create categories in the Category Config menu."
            >
                <option :value='null'>
                    No Category
                </option>
                <option
                    v-for="category in shop.categories"
                    :value="category"
                >
                    {{ category }}
                </option>
            </Field>
        </div>
        <div class="column is-12">
            <Field
                v-model="itemForm.artistName"
                label="Artist Name"
                :error="itemForm.errors.artistName" />
        </div>
        <div class="column is-12">
            <Field
                v-model="itemForm.artistUrl"
                label="Artist URL"
                :error="itemForm.errors.artistUrl"
            />
        </div>
        <div class="column is-12">
            <ButtonGroup
                v-model="itemForm.stockLimitType"
                label="Stock Limit Type"
            >
                <GroupedButton
                    class="is-warning"
                    this-value="NO_LIMIT"
                >
                    No Limit
                </GroupedButton>
                <GroupedButton
                    class="is-warning"
                    this-value="BY_PARENT"
                >
                    By Parent
                </GroupedButton>
                <GroupedButton
                    class="is-warning"
                    this-value="BY_VARIANT"
                >
                    By Variant
                </GroupedButton>
            </ButtonGroup>
        </div>
        <div class="column is-12">
            <Field
                type="number"
                v-model="itemForm.stockLimit as any"
                :disabled="itemForm.stockLimitType !== 'BY_PARENT'"
                label="Stock Limit"
                :error="itemForm.errors.stockLimit"
            />
        </div>
        <div class="column is-12">
            <ButtonGroup
                v-model="itemForm.itemType"
                label="Item Type"
            >
                <GroupedButton
                    class="is-warning"
                    this-value="physical"
                >
                    Physical
                </GroupedButton>
                <GroupedButton
                    class="is-warning"
                    this-value="digital"
                >
                    Digital
                </GroupedButton>
            </ButtonGroup>
        </div>
    </div>

    <hr>

    <template v-if="page.props.auth.can.manageItemFulfillment && itemForm.itemType === 'physical'">
        <div class="column is-12">
            <ButtonGroup
                v-model="itemForm.fulfillmentOptions.provider"
                label="Fulfillment Provider"
            >
                <GroupedButton
                    v-for="provider in page.props.services.fulfillment?.enabled_providers"
                    class="is-warning"
                    :this-value="provider.id"
                >
                    {{ provider.display_name }}
                </GroupedButton>
            </ButtonGroup>
        </div>
        <div class="column is-12">
            <template v-if="providerItemLinkingType === 'autocomplete'">
                <Autocomplete
                    v-model="providerItem"
                    :options="autocompleteOptions"
                    :error="(itemForm.errors as any).fulfillmentOptions?.providerIdentifier"
                    label="Provider Item"
                    @query="submitNewQuery"
                    :is-loading="isSearching"
                    :disabled="props.item.id > 0 || itemForm.fulfillmentOptions.provider === null"
                >
                    <template #option="{ option }">
                        <div class="is-flex is-align-items-center is-gap-2">
                            <figure
                                class="image is-48x48 is-overflow-hidden"
                                style="border-radius: 0.3rem;"
                            >
                                <LoadingImg
                                    :src="option.value.imageUrl"
                                    alt="thumbnail"
                                />
                            </figure>
                            <div>
                                <div>{{ option.name }}</div>
                                <div class="is-size-7 has-text-grey">
                                    {{ option.value.sku }}
                                </div>
                            </div>
                        </div>
                    </template>
                </Autocomplete>
            </template>
            <template v-else>
                <Field
                    v-model="itemForm.fulfillmentOptions.providerIdentifier as any"
                    label="Provider Identifier"
                    :error="(itemForm.errors as any).fulfillmentOptions?.providerIdentifier"
                    :disabled="props.item.id > 0 || itemForm.fulfillmentOptions.provider === null"
                />
            </template>
        </div>
    </template>

    <template v-if="itemForm.itemType === 'digital'">
        <div class="column is-12">
            <Field
                is="textarea"
                v-model="itemForm.redemptionInstructions"
                label="Redemption Instructions"
                :error="itemForm.errors.redemptionInstructions"
            />
        </div>
    </template>

    <hr v-if="itemForm.id > 0">

    <div
        class="column is-12"
        v-if="itemForm.id > 0"
    >

        <div class="block is-flex is-justify-content-flex-start">
            <div class="is-size-4 mr-auto has-text-white">Variants</div>
            <button class="button is-primary" @click.prevent="handleAddVariantClick">
                <span class="icon is-small">
                    <Icon :icon="mdiPlus" />
                </span>
                <span>
                    Add Variant
                </span>
            </button>
        </div>


        <div
            class="block is-flex is-align-items-center has-gap-medium has-text-white has-background-primary-15 p-3"
            v-for="(variant, variantIndex) in itemForm.variants"
            style="border-radius: .5rem;"
        >
            <div>
                <button class="button is-ghost is-small has-text-danger px-0" @click="removeVariant(variantIndex)">
                    <Icon :icon="mdiClose" />
                </button>
                <button class="button is-ghost is-small has-text-warning px-0 ml-1" @click="editVariant(variant, variantIndex)">
                    <Icon :icon="mdiPencil" />
                </button>
            </div>

            <div>{{ variant.variantName }}</div>
        </div>
    </div>

    <hr>
    <div class="is-flex is-justify-content-flex-end is-align-items-center">
        <button
            v-if="!isSaving"
            class="button is-primary is-width-fit"
            @click="saveItem"
        >
            <span class="icon is-small">
                <Icon :icon="mdiFloppy" />
            </span>
            <span>
                Save
            </span>
        </button>

        <button
            v-else
            class="button is-primary is-loading is-width-fit"
        >
            <span class="icon is-small">
                <Icon :icon="mdiFloppy" />
            </span>
            <span>
                Save
            </span>
        </button>
    </div>
</template>

<script setup lang="ts">
import type { Item } from '@/Types/Item';
import Field from '../common/Field.vue';
import {mdiClose, mdiFloppy, mdiPlus, mdiPencil} from '@mdi/js';
import type { ItemVariant } from '@/Types/ItemVariant';
import ItemVariantDialog from "@/Components/Item/ItemVariantDialog.vue";
import Icon from '@/Components/Icon.vue';
import {ref, onMounted, PropType, computed } from 'vue';
import { useShopStore } from "@/Stores/ShopStore";
import { useForm, usePage } from '@inertiajs/vue3';
import { debounce } from "lodash";
import { Autocomplete, AutocompleteOption } from "@/Components/common/Autocomplete";
import {ButtonGroup, GroupedButton} from "@/Components/common/ButtonGroup";
import LoadingImg from "@/Components/LoadingImg.vue";

const page = usePage();

const defaultVariant: ItemVariant = {
    id: 0,
    fulfillmentOptions: {
        providerVariantIdentifier: null,
    },
    variantName: '',
    color: '',
    size: '',
    colorHex: '',
    metadata: {},
};

const props = defineProps({
    shopId: {
        type: Number,
        required: true
    },
    item: {
        type: Object as PropType<Item>,
        required: false,
        default: {
            id: 0,
            slug: '',
            title: '',
            itemType: 'physical',
            description: '',
            price: 0,
            category: null,
            variants: [],
        }
    },
    edit: {
        type: Boolean,
        required: false,
        default: false
    }
});

const providerItem = ref<AutocompleteOption<{
    id: string|number,
    sku: string|number,
    name: string,
    imageUrl?: string,
}> | null>(null);

const itemForm = useForm({
    id: props.item.id,
    fulfillmentOptions: {
        provider: props.item.fulfillmentOptions?.provider ?? null,
        providerIdentifier: props.item.fulfillmentOptions?.providerIdentifier ?? null,
    },
    slug: props.item.slug,
    title: props.item.title,
    description: props.item.description,
    price: props.item.price,
    category: props.item.category,
    stockLimitType: props.item.stockLimitType ?? 'NO_LIMIT',
    stockLimit: props.item.stockLimit ?? null,
    redemptionInstructions: props.item.redemptionInstructions ?? '',
    variants: props.item.variants,
    itemType: props.item.itemType,
    goalContribution: props.item.goalContribution ?? 0,
    artistName: props.item.artistName ?? '',
    artistUrl: props.item.artistUrl ?? '',
});

const variantModalModel = ref<ItemVariant|null>(null);
const isSaving = ref(false);
const shop = useShopStore();

const providerItemLinkingType = computed<'autocomplete' | 'text'>(() => {
    if (itemForm.fulfillmentOptions.provider === 'printful') {
        return 'autocomplete';
    }

    return 'text';
});

onMounted(async () => {
    await shop.initialize(props.shopId);
    if (itemForm.fulfillmentOptions?.provider && itemForm.fulfillmentOptions?.providerIdentifier && providerItemLinkingType.value === 'autocomplete') {
        await fetchProviderItem(itemForm.fulfillmentOptions?.provider, itemForm.fulfillmentOptions?.providerIdentifier);
    }
});

const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'saved'): void;
}>();

function addVariant(newVal: ItemVariant): void {
    newVal.id = 0;
    itemForm.variants.push(JSON.parse(JSON.stringify(newVal)));
}

const editingVariantIndex = ref<number|null>(null);
function handleVariantSave(variant: ItemVariant): void {
    if (editingVariantIndex.value === null) {
        addVariant(variant);
    } else {
        itemForm.variants[editingVariantIndex.value] = JSON.parse(JSON.stringify(variant));
    }

    editingVariantIndex.value = null;
    variantModalModel.value = null;
}

function editVariant(variant: ItemVariant, idx: number): void {
    variantModalModel.value = JSON.parse(JSON.stringify(variant));
    editingVariantIndex.value = idx;
}

function handleAddVariantClick(): void {
    variantModalModel.value = {...defaultVariant};
    editingVariantIndex.value = null;
}

function handleModalClose(): void {
    variantModalModel.value = null;
    editingVariantIndex.value = null;
}

function removeVariant(idx: number): void {
    itemForm.variants.splice(idx, 1);
}

async function saveItem(): Promise<void> {
    isSaving.value = true;

    for (let i = 0; i < itemForm.variants.length; i++) {
        if (itemForm.variants[i].id === 0) {
            itemForm.variants[i].id = null;
        }
    }

    if (itemForm.id > 0 && itemForm.slug.length > 0) {
        //item exists
        const thisRoute = route('admin.shops.items.update', { shop: shop.slug, item: itemForm.slug });
        itemForm.transform((item) => {
            if (providerItemLinkingType.value === 'autocomplete') {
                item.fulfillmentOptions.providerIdentifier = providerItem.value?.id;
            }
            return item;
        }).post(thisRoute, {
            onSuccess: () => {
                emit('saved');
                emit('close');
            }
        });
    } else {
        //new item
        const thisRoute = route('admin.shops.items.create', { shop: shop.slug });
        itemForm.transform((item) => {
            if (providerItemLinkingType.value === 'autocomplete') {
                item.fulfillmentOptions.providerIdentifier = providerItem.value?.id;
            }

            return item;
        }).post(thisRoute, {
            onSuccess: () => {
                emit('saved');
                emit('close');
            },

            onError: (errors) => {
                console.error(errors);
            }
        });
    }

    isSaving.value = false;
}

const isSearching = ref(false);
const bounced = debounce(queryProviderItems, 500, { trailing: true });
const autocompleteOptions = ref<AutocompleteOption<{
    id: string|number,
    sku: string|number,
    name: string,
    imageUrl?: string,
}>[]>([]);

function submitNewQuery(query: string) {
    const provider = itemForm.fulfillmentOptions?.provider;
    if (!provider) {
        return;
    }

    bounced.cancel();
    isSearching.value = true;
    bounced(provider, query);
}

async function fetchProviderItem(provider: string, itemId: string|number) {
    try {
        const response = await fetch(route('admin.fulfillment.get_product', { id: itemId, provider }));

        let { status, payload } = await response.json();

        if (status !== 'OK') {
            throw new Error(payload);
        }

        providerItem.value = payload;
        autocompleteOptions.value.unshift(payload);

    } catch (error) {
        console.error(error);
    }
    finally {
        isSearching.value = false;
    }
}

async function queryProviderItems(provider: string, query: string) {
    try {
        if (query.length < 3) {
            autocompleteOptions.value = [];
            return;
        }

        let response = await fetch(route('admin.fulfillment.query_products', { query, provider }));

        let { status, payload } = await response.json();

        if (status !== 'OK') {
            throw new Error(payload);
        }

        autocompleteOptions.value = payload;

    } catch (error) {
        console.error(error);
    }
    finally {
        isSearching.value = false;
    }
}
</script>
