<template>
    <Dialog
        :title="isNew ? 'Create Item Variant' : 'Edit Item Variant'"
        :open="open"
        @close="() => emit('close')"
    >
        <ItemVariantForm
            v-model="model"
            :show-stock-limit="props.showStockLimit"
            :provider="props.provider"
            :product-id="props.productId"
            @save="() => emit('save', model)"
        />
    </Dialog>
</template>

<script setup lang="ts">
import ItemVariantForm from '@/Components/Item/ItemVariantForm.vue';
import type { ItemVariant } from '@/Types/ItemVariant';
import {ref, computed, watch} from 'vue';
import Dialog from '../Dialog.vue';

const defaultVariant: ItemVariant = {
    id: 0,
    fulfillmentOptions: {
        providerVariantIdentifier: null,
    },
    color: '',
    colorHex: '',
    size: '',
    stockLimit: null,
    variantName: '',
    metadata: {},
};

const model = ref<ItemVariant>({...defaultVariant});

const props = defineProps<{
    modelValue: ItemVariant;
    open: boolean;
    showStockLimit?: boolean;
    provider?: string;
    productId?: string;
}>();

watch(() => props.modelValue, (newVal) => {
    model.value = newVal;
}, {deep: true, immediate: true});

const emit = defineEmits<{
    (e: 'update:modelValue', value: ItemVariant): void;
    (e: 'save', value: ItemVariant): void;
    (e: 'close'): void;
}>();

const isNew = computed(() => {
    return (props.modelValue?.id ?? 0) === 0;
});

</script>
