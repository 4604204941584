<template>
    <div class="is-flex is-flex-direction-column has-gap-medium">

        <div v-if="goals.length === 0" class="message is-info">
            <div class="message-body">
                No goals found
            </div>
        </div>

        <div v-else v-for="(goal, i) in goals" class="p-4 has-background-primary-10 is-12"
            style="border-radius: .5rem;">
            <Field v-model="goal.name" label="Name" :error="errorIndex === i ? errors.name : ''" />
            <div class="my-3">
                <ButtonGroup v-model="goal.type" label="Goal Type">
                    <GroupedButton this-value="target" class="is-warning">
                        Target
                    </GroupedButton>
                    <GroupedButton this-value="indeterminate" class="is-warning">
                        Indeterminate
                    </GroupedButton>
                </ButtonGroup>
            </div>
            <Field v-if="goal.type === 'target'" type="number" :model-value="goal.target.toString()"
                @update:model-value="goal.target = Number($event)" label="Target" :error="errors.target" />
            <Field type="text" is="textarea" rows="5" v-model="goal.description" label="Description"
                :error="errorIndex === i ? errors.description : ''" />
            <div class="is-flex is-flex-direction-column">
                <label class="label">Close Time (in current timezone)</label>
                <DateTimePicker :class="{ 'is-danger': errors.dateClose && errorIndex === i }"
                    v-model="goal.dateClose" />
                <div v-if="errors.dateClose && errorIndex === i" class="has-text-danger help">{{ errors.dateClose }}
                </div>
            </div>
            <div class="mt-3">
                <FileInput label="Image" accept="image/*" helper-text="Recommended size: 4:1 aspect ratio"
                    @change="goal.bannerUrl = ($event.target as any).files[0]"
                    :error="errorIndex === i ? errors.bannerUrl : ''" />
            </div>

            <div class="is-flex is-align-items-center has-gap-small mt-5">
                <button class="button is-primary" @click="saveGoal(goal, i)">Save</button>
                <button class="button is-danger is-ghost" @click="removeGoal(goal)">Remove</button>
            </div>
        </div>

        <button class="button is-warning is-width-fit mt-4" @click="addGoal">Add Goal</button>
    </div>
</template>

<script setup lang="ts">
import Field from '../common/Field.vue';
import type { Shop } from '@/Types/Shop';
import { onMounted, PropType, ref } from 'vue';
import { router, useForm } from '@inertiajs/vue3';
import { useShopStore } from '@/Stores/ShopStore';
import FileInput from "@/Components/common/FileInput.vue";
import { Goal } from '@/Types/Goal';
import DateTimePicker from '../DateTimePicker.vue';
import ButtonGroup from '../common/ButtonGroup/ButtonGroup.vue';
import GroupedButton from '../common/ButtonGroup/GroupedButton.vue';

const props = defineProps({
    shop: {
        type: Object as PropType<Shop>,
        required: false,
        default: {
            id: 0,
            name: '',
            slug: '',
            description: '',
            goals: []
        }
    }
});

const emit = defineEmits<{
    (e: "close"): void;
}>();

const shop = useShopStore();
const goals = ref<Goal[]>(props.shop.goals);
const errors = ref<any>({});
const errorIndex = ref(-1);

onMounted(() => {
    shop.initialize(props.shop.id);
})

function addGoal() {
    goals.value.push({
        id: 0,
        name: '',
        shopId: props.shop.id,
        bannerUrl: '',
        description: '',
        current: 0,
        target: 0,
        dateClose: '',
        type: 'target'
    });
}

function removeGoal(goal: Goal) {
    if (!confirm('Are you sure you want to delete this goal?')) {
        return;
    }
    if (goal.id === 0) {
        goals.value = goals.value.filter(g => g !== goal);
        return;
    }
    const thisRoute = route('admin.shops.goals.delete', { shop: props.shop.slug, goal: goal.id });
    router.delete(thisRoute);
    goals.value = goals.value.filter(g => g.id !== goal.id);
}

async function saveGoal(goal: Goal, index: number) {
    const form = useForm({
        ...goal
    });

    let thisRoute = route('admin.shops.goals.store', { shop: props.shop.slug });

    if (goal.id > 0) {
        thisRoute = route('admin.shops.goals.update', { shop: props.shop.slug, goal: goal.id });
    }

    form.post(thisRoute, {
        onSuccess: async () => {
            await shop.refresh();
        },
        onError: (e) => {
            console.error(e);
            errors.value = e;
            errorIndex.value = index;
        }
    });
}

</script>

<style lang="scss" scoped>
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
</style>
