<template>
    <div class="box is-height-fit">
        <PageHead title="Verify Email" />

        <div class="box-title">
            <h1>
                Verify Email
            </h1>
        </div>

        <div>
            <div>
                <p>
                    Check your inbox! We've sent you an email with a link to verify your email address.
                </p>
                <div class="is-flex is-justify-content-flex-end">
                    <Link class="button is-primary" :href="route('login')">
                        <span class="icon is-small">
                            <Icon :icon="mdiEmail" />
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Icon from '@/Components/Icon.vue';
import { mdiEmail } from '@mdi/js'
import { useForm } from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';
import Base from '@/Layouts/Base.vue';
import Default from '@/Layouts/Default.vue';
import PageHead from '@/Components/PageHead.vue';

defineOptions({
    layout: [Base, Default],
})

const isLoading = ref(false);
const sentReset = ref(false);

const form = useForm({
    email: ''
});
</script>

<style lang="scss" scoped>
    @use 'bulma/sass/utilities/mixins';

    .box {
        max-width: 25rem;
        width: 100%;
    }

    @include mixins.mobile {
        .box {
            min-width: 100%;
            width: 100%;
        }
    }
</style>
