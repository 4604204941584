<template>
    <Dialog
        :open="![null, undefined].includes(props.item) && !codeToDelete"
        @close="emit('close')"
        title="Item Codes"
    >
        <div class="is-flex is-justify-content-flex-end">
            <div class="mb-4">
                <button
                    class="button is-small"
                    @click.prevent="handleFileUpload"
                >
                    Upload Codes
                </button>
            </div>
        </div>
        <div class="overflow-y-auto">
            <template v-if="!isLoading && itemCodes.length === 0">
                <p class="has-text-centered">
                    No item codes found.
                </p>
            </template>
            <template v-else-if="isLoading">
                <div
                    v-for="i in 5"
                    class="skeleton-block mb-2 has-radius-large"
                    style="width: 100%; height: 50px;"
                >

                </div>
            </template>
            <template v-else>
                <div
                    v-for="code in itemCodes"
                    class="mb-2 box"
                >
                    <div class="columns">
                        <div class="column">
                            <span class="is-size-5">
                                {{ code.code }}
                            </span>
                            <span
                                v-if="code.fulfilledLineItemId"
                                class="tag is-warning is-outlined ml-2"
                            >
                                Used
                            </span>
                        </div>
                        <div class="column is-narrow">
                            <div class="is-flex is-gap-2 is-flex-direction-column is-align-items-center is-justify-content-center">
                                <button
                                    class="button is-small is-danger is-outlined"
                                    @click.prevent="codeToDelete = code"
                                >
                                    Delete Code
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <AppPagination
            :total="pageInfo?.total"
            :last-page="pageInfo?.lastPage"
            :from="pageInfo?.from"
            :to="pageInfo?.to"
            :current-page="pageInfo?.currentPage"
            @update:page="fetchItemCodes($event)"
        />
        <hr>

        <div>
            <Field
                is="checkbox"
                v-model="onlyUnused"
                label="Only show unused codes"
            />
        </div>
    </Dialog>

    <Dialog
        title="Are you sure you want to delete this code?"
        :open="![null, undefined].includes(codeToDelete)"
        @close="codeToDelete = null"
    >
        <div class="buttons">
            <button
                class="button is-outlined is-light"
                @click.prevent="codeToDelete = null"
            >
                Cancel
            </button>
            <button
                class="button is-danger"
                @click.prevent="deleteCode"
            >
                Delete Code
            </button>
        </div>
    </Dialog>
</template>

<script setup lang="ts">
import Dialog from "@/Components/Dialog.vue";
import {Item} from "@/Types/Item";
import {DigitalItemCodeStock} from "@/Types/DigitalItemCodeStock";
import {nextTick, ref, watch} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import axios from "axios";
import Field from "@/Components/common/Field.vue";
import { PageInfo } from "@/Types/PageInfo";
import AppPagination from "@/Components/common/AppPagination.vue";

const page = usePage();

const props = defineProps<{
    item?: Item;
}>();
const emit = defineEmits<{
    (event: 'close'): void;
}>();

const onlyUnused = ref(false);

const itemCodes = ref<DigitalItemCodeStock[]>([]);
const pageInfo = ref<PageInfo | null>(null);

const codeToDelete = ref<DigitalItemCodeStock | null>(null);

watch(() => [props.item, onlyUnused], async ([item]) => {
    await nextTick();
    if (props.item) {
        itemCodes.value = await fetchItemCodes();
    }
    else {
        itemCodes.value = [];
    }
});

const isLoading = ref<boolean>(false);

async function fetchItemCodes(thisPage: number = 1): Promise<DigitalItemCodeStock[]> {
    try {
        isLoading.value = true;
        const thisRoute = route('admin.shops.items.codes.fetch', {
            shop: page.props.shopSlug,
            item: props.item.slug,
            onlyUnused: onlyUnused.value,
            page: thisPage,
        });

        const { status, payload } = (await axios.get(thisRoute)).data;
        if (status !== 'OK') {
            throw new Error('Failed to fetch item codes');
        }

        pageInfo.value = payload.pageInfo;

        return payload.codes as DigitalItemCodeStock[];
    } catch (e) {
        console.error(e);
        return [];
    } finally {
        isLoading.value = false;
    }
}

const codeUploadForm = useForm({
    file: null,
});

const isUploading = ref<boolean>(false);

function handleFileUpload() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.csv';
    fileInput.onchange = (e) => {
        const files = (e.target as HTMLInputElement).files;
        if (files && files.length > 0) {
            codeUploadForm.file = files[0];
            codeUploadForm.post(route('admin.shops.items.codes.upload', {
                shop: page.props.shopSlug,
                item: props.item.slug,
            }), {
                preserveScroll: true,
                onStart: () => {
                    isUploading.value = true;
                },
                onFinish: async () => {
                    itemCodes.value = await fetchItemCodes();
                    fileInput.remove();
                    isUploading.value = false;
                },
            });
        }
    };
    fileInput.click();
}

const isDeleting = ref<boolean>(false);
async function deleteCode() {
    if (codeToDelete.value) {
        try {
            const thisRoute = route('admin.shops.items.codes.delete', {
                shop: page.props.shopSlug,
                item: props.item.slug,
                digitalItemCodeStock: codeToDelete.value?.id,
                code: codeToDelete.value.id,
            });

            router.delete(thisRoute, {
                preserveScroll: true,
                onStart: () => {
                    isDeleting.value = true;
                },
                onFinish: async () => {
                    itemCodes.value = await fetchItemCodes();
                    codeToDelete.value = null;
                    isDeleting.value = false;
                },
            });
        } catch (e) {
            console.error(e);
        }
    }
}

</script>
