<template>
    <div class="columns is-multiline">
        <div class="column is-6">
            <Field
                v-model="model.color"
                label="Color"
            />
        </div>
        <div class="column is-6">
            <Field
                v-model="model.colorHex"
                label="Color Hex"
                type="color"
            />
        </div>

        <div class="column is-12">
            <Field
                v-model="model.size"
                label="Size"
                is="select"
            >
                <template :key="size" v-for="size in ['One Size', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL', '6XL']">
                    <option :value="size" >{{ size }}</option>
                </template>
            </Field>
        </div>
        <div class="column is-12">
            <Field
                v-model="model.variantName"
                label="Variant Name"
            />
        </div>

        <div class="column is-12">
            <Autocomplete
                v-if="providerItemLinkingType === 'autocomplete'"
                v-model="providerItem"
                :options="autocompleteOptions"
                label="Provider Variant"
            >
                <template #option="{ option }">
                    <div class="is-flex is-align-items-center is-gap-2">
                        <figure
                            class="image is-48x48 is-overflow-hidden"
                            style="border-radius: 0.3rem;"
                        >
                            <LoadingImg
                                :src="option.value.imageUrl"
                                alt="thumbnail"
                            />
                        </figure>
                        <div>
                            <div>{{ option.name }}</div>
                            <div class="is-size-7 has-text-grey">
                                {{ option.value.sku }}
                                <template v-if="option.value.color || option.value.size">
                                    - {{ [option.value.color, option.value.size].filter(o => o).join(', ') }}
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </Autocomplete>

            <Field
                v-else
                v-model="model.fulfillmentOptions.providerVariantIdentifier"
                label="Provider Variant Identifier"
            />
        </div>
<!--        <div class="column is-12">-->
<!--            <button-->
<!--                class="button is-primary"-->
<!--                @click.prevent="() => ($refs.fileInput as HTMLElement).click()"-->
<!--            >-->
<!--                <span class="icon is-small">-->
<!--                    <Icon :icon="mdiUpload" />-->
<!--                </span>-->
<!--                <span>-->
<!--                    Upload Image-->
<!--                </span>-->
<!--            </button>-->
<!--        </div>-->

        <template v-if="props.showStockLimit">
            <hr>

            <div class="column is-12">
                <Field
                    v-model="model.stockLimit as any"
                    label="Stock Limit"
                    type="number"
                />
            </div>
        </template>
    </div>

    <hr>

    <div class="is-flex is-justify-content-flex-end">
        <div class="buttons">
            <button
                v-if="!modelIsNew"
                class="button is-danger is-outlined"
                @click.prevent="() => emit('delete')"
            >
                <span class="icon is-small">
                    <Icon :icon="mdiTrashCanOutline" />
                </span>
                <span>
                    Delete
                </span>
            </button>
            <button
                class="button is-primary"
                @click.prevent="() => emit('save', model)"
            >
                <span class="icon is-small">
                    <Icon :icon="mdiFloppy" />
                </span>
                <span>
                    Save
                </span>
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, nextTick, watch,} from 'vue';
import type { ItemVariant } from '@/Types/ItemVariant';
import Field from '../common/Field.vue';
import { mdiTrashCanOutline, mdiFloppy } from '@mdi/js';
import { ref } from 'vue';
import Icon from '../Icon.vue';
import {Autocomplete, AutocompleteOption} from "@/Components/common/Autocomplete";
import LoadingImg from "@/Components/LoadingImg.vue";

const props = defineProps<{
    modelValue: ItemVariant;
    showStockLimit?: boolean;
    provider?: string;
    productId?: string;
}>();

const autocompleteOptions = ref<AutocompleteOption<{
    id: string|number,
    sku: string|number,
    name: string,
    color?: string,
    size?: string,
    imageUrl?: string,
}>[]>([]);

const model = ref<ItemVariant>(props.modelValue);

watch(() => props.modelValue, (newVal) => {
    model.value = newVal;
});

const providerItemLinkingType = computed<'autocomplete' | 'text'>(() => {
    if (props.provider === 'printful') {
        return 'autocomplete';
    }

    return 'text';
});

watch(() => [props.provider, props.productId], ([newProvider, newProductId]) => {
    if (providerItemLinkingType.value !== 'autocomplete') {
        return;
    }

    if (newProvider && newProductId) {
        nextTick(() => {
            getProductVariants(newProvider, newProductId);
        })
    } else {
        autocompleteOptions.value = [];
    }
}, {immediate: true});

const modelIsNew = computed(() => {
    return (props.modelValue?.id ?? 0) === 0;
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: ItemVariant): void;
    (e: 'save', value: ItemVariant): void;
    (e: 'delete'): void;
}>();

const providerItem = ref<AutocompleteOption<{
    id: string|number,
    sku: string|number,
    name: string,
    color?: string,
    size?: string,
    imageUrl?: string,
}> | null>(null);

watch(providerItem, (newProviderItem) => {
    if (providerItemLinkingType.value !== 'autocomplete') {
        return;
    }

    if (newProviderItem) {
        let modelCopy = {...model.value};
        modelCopy.fulfillmentOptions.providerVariantIdentifier = newProviderItem.value.sku.toString();
        model.value = modelCopy;
    } else {
        let modelCopy = {...model.value};
        modelCopy.fulfillmentOptions.providerVariantIdentifier = null;
        model.value = modelCopy;
    }
}, {deep: true});

async function getProductVariants(provider: string, itemId: string|number) {
    try {
        let response = await fetch(route('admin.fulfillment.get_product_variants', { provider, id: itemId }));

        let { status, payload } = await response.json();

        if (status !== 'OK') {
            throw new Error(payload);
        }

        autocompleteOptions.value = payload;

        if (model.value.fulfillmentOptions?.providerVariantIdentifier) {
            let selectedOption = autocompleteOptions.value.find(o => o.value.sku === model.value.fulfillmentOptions?.providerVariantIdentifier);
            providerItem.value = selectedOption ?? null;
        }

    } catch (error) {
        console.error(error);
    }
}

async function getVariant(provider: string, sku: string|number) {
    try {
        let response = await fetch(route('admin.fulfillment.get_variant', { provider, id: sku }));

        let { status, payload } = await response.json();

        if (status !== 'OK') {
            throw new Error(payload);
        }

        return payload;

    } catch (error) {
        console.error(error);
    }
}

</script>
