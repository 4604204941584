<template>
    <div>
        <template v-if="shop.loading">

            <PageHead title="" description="" image="" />

            <div class="shop-item-wrapper">

                <div class="img-selector">
                    <div class="skeleton-block" style="aspect-ratio: 1/1; max-height: 60vh;">
                    </div>
                </div>

                <div class="item-options mx-auto">
                    <div class="box is-flex is-flex-direction-column has-gap-large">
                        <div class="is-size-2 skeleton-lines mb-0">
                            <div />
                        </div>
                        <div class="is-size-1 skeleton-lines mb-0">
                            <div />
                        </div>
                        <div class="is-size-1 skeleton-lines mb-5">
                            <div />
                        </div>

                        <PriceTag class="is-skeleton is-size-3 is-size-4-mobile my-3 is-width-fit">$0.00</PriceTag>

                        <div class="skeleton-lines mb-4 mt-4">
                            <div />
                            <div />
                            <div />
                        </div>

                        <div class="is-flex is-flex-direction-column has-gap-small">
                            <div v-for="i in 4" class="buttons">
                                <div v-for="j in (5 - i)" class="button is-skeleton">Loading</div>
                            </div>
                        </div>

                        <button class="button is-skeleton is-large is-width-100">
                            <span>
                                Add to Cart
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <div class="shop-item-wrapper" v-else-if="item">

            <PageHead :title="item.title" :description="item.description" :image="item.images[0]?.url" />

            <div v-if="item?.images?.length > 0" class="img-selector">
                <ImgSelector :srcArr="images" :detailed="true" v-model="imageIndex" :labels="imageLabels"
                    :relevantIndices="selectedVariantImageIndices" />
            </div>

            <div class="box item-options is-flex is-flex-direction-column is-align-items-flex-start mx-auto"
                style="gap: 2rem;">

                <div class="is-flex is-flex-direction-column has-gap-medium">

                    <Link :href="route('shop.show', { shop: shop.slug })"
                        class="is-flex is-align-items-center is-width-100 has-gap-medium">
                    <LoadingImg :src="shop.logoUrl" style="width: 2em; height: 2em; border-radius: .3em;" />
                    <div class="box-title m-0">{{ shop.name }}</div>
                    </Link>

                    <h1 class="is-size-3 has-text-weight-medium mb-1">{{ item?.title }}</h1>

                    <div v-if="item.artistName"
                        class="is-flex is-align-items-baseline has-gap-small is-size-5 is-flex-wrap-wrap">
                        <div>Design by:</div>
                        <a v-if="item.artistUrl" :href="item.artistUrl"
                            class="has-text-weight-medium is-flex is-align-items-center has-gap-small" target="_blank">
                            <div>{{ item.artistName }}</div>
                            <Icon :icon="mdiOpenInNew" />
                        </a>
                        <div v-else class="has-text-weight-medium">{{ item.artistName }}</div>
                    </div>

                </div>

                <div class="is-flex is-align-items-center has-gap-medium is-flex-wrap-wrap">
                    <PriceTag class="is-size-3 is-size-4-mobile has-text-weight-semibold my-3">
                        <div class="pb-1">${{ item?.price.toFixed(2) }}</div>
                    </PriceTag>
                    <div v-if="!inStock" class="tag is-warning is-size-4 is-size-5-mobile">
                        Sold Out!
                    </div>
                </div>

                <div class="is-flex is-flex-direction-column has-gap-small is-size-6">
                    <p class="block" v-if="item?.description?.length > 0"
                        v-html="item.description.replaceAll('\n', '<br>')">
                    </p>

                    <div class="block is-flex is-flex-direction-column has-gap-small is-size-6">
                        <div v-if="inStock && stockCount > 0" class="is-flex is-align-items-center has-gap-small">
                            <Icon :icon="mdiPackageVariant" />
                            {{ stockCount }} left in stock!
                        </div>

                        <div v-if="item?.goalContribution && shop.goals.length > 0" class="is-flex is-align-items-center has-gap-small">
                            <Icon :icon="mdiVote" />
                            <template v-if="shop.goalType === 'dollar'">
                                This item contributes ${{ item.goalContribution.toFixed(2) }} to a goal!
                            </template>
                            <template v-else-if="shop.goalType === 'vote'">
                                This item contributes {{ item.goalContribution }} vote{{ item.goalContribution !== 1 ?
                                's' : '' }} to a
                                goal!
                            </template>
                        </div>
                    </div>
                </div>

                <template v-if="shopIsOpen(shop.dateOpen, shop.dateClose)">

                    <div class="is-flex is-flex-direction-column has-gap-large">
                        <div
                            class="is-flex is-flex-direction-column has-gap-small"
                            v-if="sizeOptions && sizeOptions?.size > 0"
                        >
                            <label>Size</label>
                            <div class="buttons">
                                <button
                                    v-for="(variant, variantIndex) in sizeOptions"
                                    :key="variant"
                                    class="button is-primary has-text-white"
                                    :class="{ 'is-outlined': selectedSizeIndex !== variantIndex }"
                                    @click="selectedSizeIndex = variantIndex"
                                >
                                    {{ variant }}
                                </button>
                            </div>
                        </div>

                        <div
                            class="is-flex is-flex-direction-column has-gap-small"
                            v-if="colorOptions && colorOptions.size > 0"
                        >
                            <label>Color</label>
                            <div class="buttons">
                                <button
                                    v-for="(variant, variantIndex) in colorOptions"
                                    :key="variant.label"
                                    class="button is-primary has-text-white"
                                    :class="{ 'is-outlined': selectedColorIndex !== variantIndex }"
                                    @click="selectedColorIndex = variantIndex"
                                >
                                    <span
                                        v-if="variant.hex"
                                        class="color-preview"
                                        :style="{ backgroundColor: variant.hex }"
                                    />
                                    <span :class="{ 'ml-3': variant.hex }">{{ variant.label }}</span>
                                </button>
                            </div>
                        </div>

                        <div class="is-flex is-flex-direction-column has-gap-small">
                            <label>Quantity</label>
                            <div class="is-width-fit">
                                <QuantitySelector v-model="quantity" :min="1" :max="99" />
                            </div>
                        </div>
                    </div>

                    <template v-if="!isAddingToCart && canAddToCart.status">
                        <button class="button is-primary is-large is-width-100" @click="addToCart">
                            <span class="icon is-small is-width-100">
                                <Icon :icon="mdiCartPlus" />
                            </span>
                            <span>
                                Add to Cart
                            </span>
                        </button>
                    </template>

                    <template v-else-if="!isAddingToCart && !canAddToCart.status">
                        <div class="is-flex has-gap-medium is-align-items-center is-flex-wrap-wrap is-width-100">
                            <button class="button is-primary is-large is-width-100" disabled>
                                <span class="icon is-small">
                                    <Icon :icon="mdiCartPlus" />
                                </span>
                                <span>
                                    Add to Cart
                                </span>
                            </button>
                            <div class="has-text-link">
                                {{ canAddToCart.requiredMessage }}
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <button class="button is-primary is-large is-loading is-width-100">
                            <span class="icon is-small">
                                <Icon :icon="mdiCartPlus" />
                            </span>
                            <span>
                                Add to Cart
                            </span>
                        </button>
                    </template>
                </template>

                <template v-else>
                    <div class="is-flex is-flex-direction-column has-gap-large is-width-100">
                        <div
                            class="is-flex is-flex-direction-column has-gap-small"
                            v-if="sizeOptions && sizeOptions?.size > 0"
                        >
                            <label>Size</label>
                            <div class="buttons">
                                <button
                                    v-for="(variant, variantIndex) in sizeOptions"
                                    :key="variant"
                                    class="button is-primary has-text-white"
                                    :class="{ 'is-outlined': selectedSizeIndex !== variantIndex }"
                                    @click="selectedSizeIndex = variantIndex"
                                >
                                    {{ variant }}
                                </button>
                            </div>
                        </div>

                        <div
                            class="is-flex is-flex-direction-column has-gap-small"
                            v-if="colorOptions && colorOptions.size > 0"
                        >
                            <label>Color</label>
                            <div class="buttons">
                                <button
                                    v-for="(variant, variantIndex) in colorOptions"
                                    :key="variant.label"
                                    class="button is-primary has-text-white"
                                    :class="{ 'is-outlined': selectedColorIndex !== variantIndex }"
                                    @click="selectedColorIndex = variantIndex"
                                >
                                    <span
                                        v-if="variant.hex"
                                        class="color-preview"
                                        :style="{ backgroundColor: variant.hex }"
                                    />
                                    <span :class="{ 'ml-3': variant.hex }">{{ variant.label }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="button is-large is-primary is-width-100" disabled>
                        <span class="icon is-small is-width-100">
                            <Icon :icon="mdiCartRemove" />
                        </span>
                        <span>
                            Shop is Closed
                        </span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Item } from '@/Types/Item';
import { mdiCartPlus, mdiCartRemove, mdiOpenInNew, mdiPackageVariant, mdiVote } from '@mdi/js';
import type { ItemVariant } from '@/Types/ItemVariant';
import HasCart from '@/Layouts/HasCart.vue';
import { useCartStore } from "@/Stores/CartStore";
import { useShopStore } from "@/Stores/ShopStore";
import { computed, onMounted, ref, watch } from "vue";
import { Link, usePage } from "@inertiajs/vue3";
import ImgSelector from "@/Components/ImgSelector.vue";
import Icon from "@/Components/Icon.vue";
import Base from '@/Layouts/Base.vue';
import QuantitySelector from '@/Components/QuantitySelector.vue';
import LoadingImg from '@/Components/LoadingImg.vue';
import PriceTag from '@/Components/PriceTag.vue';
import PageHead from '@/Components/PageHead.vue';
import { shopIsOpen } from '@/Helpers/shopTime';

defineOptions({
    layout: [Base, HasCart]
});

type SizeOption = string;
type ColorOption = {
    hex?: string;
    label: string;
};

const cart = useCartStore();
const shop = useShopStore();
const item = ref<Item | null>(null);
const isAddingToCart = ref(false);
const quantity = ref(1);
const sizeOptions = ref<Set<SizeOption> | null>(null);
const colorOptions = ref<Set<ColorOption> | null>(null);
const selectedSizeIndex = ref<number | null>(null);
const selectedColorIndex = ref<number | null>(null);
const imageIndex = ref(0);
const selectedVariantImageIndices = ref<number[]>([]);

const images = computed(() => {
    return item.value?.images.map((image) => image.url) ?? [];
});

const imageLabels = computed(() => {
    const labels: Record<number, string> = {};
    if (!item.value || !item.value.images) {
        return labels;
    }
    for (const i in item.value.images) {
        const image = item.value.images[i];
        if (image.variantId) {
            labels[i] = item.value.variants.find((variant) => variant.id === image.variantId)?.variantName ?? '';
        }
    }
    return labels;
});

const matchingVariant = computed<ItemVariant|undefined>(() => {
    const size = sizeOptions.value ? Array.from(sizeOptions.value)[selectedSizeIndex.value!] : null;
    const color = colorOptions.value ? Array.from(colorOptions.value)[selectedColorIndex.value!] : null;

    return item.value?.variants?.find?.((el: ItemVariant) => {
        return el.size === size && el.color === color?.label;
    });
});

const inStock = computed(() => {
    if (matchingVariant.value && matchingVariant.value.stockLimit) {
        return (matchingVariant.value.stockLimit - matchingVariant.value.metadata.qtySold) > 0;
    }

    if (item.value.stockLimit) {
        return (item.value.stockLimit - item.value.metadata.qtySold) > 0
    }
    return true;
})

const stockCount = computed(() => {
    if (matchingVariant.value && matchingVariant.value.stockLimit) {
        return matchingVariant.value.stockLimit - matchingVariant.value.metadata.qtySold;
    }

    return item.value.stockLimit - item.value.metadata.qtySold;
});

const canAddToCart = computed(() => {
    if (!inStock.value) {
        return { status: false, requiredMessage: '' };
    }
    let status = true;
    const required = [];
    if ((sizeOptions.value?.size ?? 0) > 0 && selectedSizeIndex.value === null) {
        required.push('Size');
        status = false;
    }
    if ((colorOptions.value?.size ?? 0) > 0 && selectedColorIndex.value === null) {
        required.push('Color');
        status = false;
    }
    if (quantity.value < 1 || isNaN(quantity.value)) {
        required.push('Quantity');
        status = false;
    }
    if (item.value?.stockLimit && item.value.metadata.qtySold >= item.value.stockLimit) {
        status = false;
    }

    return { status: status, requiredMessage: `Required selections: ${required.join(', ')}.` };
});

const page = usePage();

const props = defineProps<{
    item: Item;
}>();

onMounted(async () => {
    await shop.initialize(page.props.shopId);

    console.log(props.item);

    item.value = props.item;

    const newSizeOptions: Set<SizeOption> = new Set();
    const newColorOptions: Set<ColorOption> = new Set();

    const colorNames: Set<string> = new Set();

    item.value?.variants.forEach((variant) => {
        if (variant.size) {
            newSizeOptions.add(variant.size);
        }
        if (variant.color) {
            if (!colorNames.has(variant.color)) {
                colorNames.add(variant.color);
                newColorOptions.add({ hex: variant.colorHex, label: variant.color });
            }
        }
    });

    sizeOptions.value = newSizeOptions;
    colorOptions.value = newColorOptions;

    if (sizeOptions.value.size === 1) {
        selectedSizeIndex.value = 0;
    }
    if (colorOptions.value.size === 1) {
        selectedColorIndex.value = 0;
    }
});

watch(selectedSizeIndex, (value) => {
    if (value !== null) {
        updateRelevantImages();
    }
});

watch(selectedColorIndex, (value) => {
    if (value !== null) {
        updateRelevantImages();
    }
});

async function addToCart() {
    if (!item.value) {
        return;
    }
    isAddingToCart.value = true;

    const cartItem: Item = JSON.parse(JSON.stringify(item.value));

    if (!matchingVariant.value && item.value.variants.length > 0) {
        console.error('No matching variant found', matchingVariant.value);
        isAddingToCart.value = false;
        return;
    }

    await cart.addItem(cartItem, matchingVariant.value, quantity.value);
    isAddingToCart.value = false;
}

watch(() => matchingVariant, () => {
    updateImageIndex(matchingVariant.value!.id);
    updateRelevantImages();
});

function updateImageIndex(variantId: number) {
    const images = item.value.images.filter((image) => image.variantId === variantId);
    if (images.length > 0) {
        imageIndex.value = item.value.images.indexOf(images[0]);
    }
}

function updateRelevantImages() {
    if (!matchingVariant.value) {
        selectedVariantImageIndices.value = [];
    }
    else {
        const relevantIndices: number[] = [];

        for (let i = 0; i < item.value.images.length; i++) {
            const image = item.value.images[i];
            if (image.variantId === matchingVariant.value.id) {
                relevantIndices.push(i);
            }
        }

        selectedVariantImageIndices.value = relevantIndices;
    }
}

</script>

<style lang="scss" scoped>
@use "bulma/sass/utilities/mixins";

.img-selector {
    height: fit-content;
    min-height: 30rem;
    width: 100%;
    position: relative;
}

.item-options {
    width: 100%;
    height: fit-content;
}

.shop-item-wrapper {
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.color-preview {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: white solid .1rem;
}

@include mixins.widescreen {
    .shop-item-wrapper {
        width: 100%;
        flex-direction: row;
    }
}

@include mixins.until-widescreen {
    .img-selector {
        max-width: 60vh;
        min-width: 40rem;
        margin: auto;
    }
}

@include mixins.touch {
    .img-selector {
        max-height: 100%;
    }
}

@include mixins.mobile {
    .img-selector {
        max-width: 35rem;
        min-width: 0;
    }
}
</style>
